<template>
  <b-table
    responsive="sm"
    :fields="fields"
    :items="dataList"
    striped
    fixed
  >
    <template #cell(title)="data">
      {{ data.item.title }}
      <div v-if="data.item.onumber">
        <small class="text-primary">{{ data.item.onumber }}</small>
      </div>
    </template>
    <template #cell(username)="data">
      {{ data.item.username }}
    </template>
    <template #cell(control)="data">
      <list-buttons
        primary-text="Görüntüle"
        :primary-action="'/offers/view/' + data.item.id"
        edit-text="Düzenle"
        :edit-action="'/offers/edit/' + data.item.id"
        :data-id="data.item.id"
      />
    </template>
  </b-table>
</template>
<script>
import { BTable } from 'bootstrap-vue'
import ListButtons from '@/layouts/components/common/ListDropdownButtons.vue'

export default {
  name: 'OfferList',
  components: {
    BTable,
    ListButtons,
  },
  props: {
    fields: {
      type: Array,
      required: true,
    },
    dataList: {
      type: Array,
      required: true,
    },

  },
}
</script>
